import { ITechnologyItem } from '@components/main-page/main-page.state';
import style from './index.module.scss'
import { TechnologiesItem } from './technologies-item';


interface ITechnologiesTableProps {
    title: string;
    isActive: string;
    technologies: ITechnologyItem[];
}

export const TechnologiesTable = ({ title, isActive, technologies }: ITechnologiesTableProps) => {
    return (
        <div className={`${style.technologies__table} ${title === isActive ? style.technologies__table_active : null}`}>
            {
                technologies.map((item: ITechnologyItem, index: number) => {
                    return(
                        <TechnologiesItem key={index} title={item.title} technologies={item.technologies} />
                    )
                })
            }
        </div>
    )
}