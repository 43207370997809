import { ITechnologies } from '@components/main-page/main-page.state';
import { Dispatch, SetStateAction } from 'react';
import style from './index.module.scss'
import { TechnologyButton } from './technology-button';

interface ITechnologiesButtonsProps {
    technologies: ITechnologies[];
    activeTitle: string;
    setActive: Dispatch<SetStateAction<string>>
}

export const TechnologiesButtons = ({ technologies, activeTitle, setActive }: ITechnologiesButtonsProps) => {
    return (
        <div className={`${style.technologies__buttons}`}>
            {
                technologies.map((item: ITechnologies, index: number) => {
                    return (
                        <TechnologyButton key={index} title={item.title} activeTitle={activeTitle} setActive={() => setActive(item.title)}/>
                    )
                })
            }
        </div>
    )
}