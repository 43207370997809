import { ITechnology } from '@components/main-page/main-page.state';
import { TechnologyIcon } from '../technology-icon';
import style from './index.module.scss'


interface ITechnologiesItemProps {
    title: string;
    technologies: ITechnology[];
}

export const TechnologiesItem = ({ title, technologies }: ITechnologiesItemProps) => {
    return (
        <div className={`${style.technologies}`}>
            <div className={`${style.technologies__title}`}>
                {title}
            </div>
            <div className={`${style.technologies__items}`}>
            {
                technologies.map((item: ITechnology, index: number) => {
                    return(
                        <TechnologyIcon key={index} title={item.title} width={item.width} height={item.height} iconSrc={item.iconSrc} />
                    )
                })
            }
            </div>
            
        </div>
    )
}