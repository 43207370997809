import Image from 'next/image';
import style from './index.module.scss'


interface ITechnologyIconProps {
    title: string;
    width: string;
    height: string;
    iconSrc: string;
}

export const TechnologyIcon = ({ title, width, height, iconSrc }: ITechnologyIconProps) => {
    return (
            <div className={`${style.technology}`}>
                <div className={`${style.technology__image}`} >
                    <Image loading='lazy' src={iconSrc} width={width} height={height} alt={title}/>
                    <div className={`${style.technology__hover}`}>
                        {title}
                    </div>
                </div>
            </div>
    );
}