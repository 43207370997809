import style from './index.module.scss'

interface ITechnologyButtonProps {
    title: string;
    activeTitle: string;
    setActive: () => void;
}

export const TechnologyButton = ({ title, activeTitle, setActive }: ITechnologyButtonProps) => {
    return (
        <div onClick={setActive} className={`${style.technologies__button} ${activeTitle === title ? style.technologies__button_active : null}`}>
            {title}
        </div>
    )
}