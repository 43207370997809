import style from './index.module.scss'
import template from '@styles/reusable.styles.module.scss';
import { ITechnologies } from '@components/main-page/main-page.state';
import { TechnologiesButtons } from './technologies-buttons';
import { useState } from 'react';
import { TechnologiesTable } from './technologies-table';

interface ITechnologiesSectionProps {
    title: string;
    technologies: ITechnologies[];
}


const TechnologiesSection = ({ title, technologies }: ITechnologiesSectionProps) => {
    const [technologyListActive, setTechnologyListActive] = useState(technologies[0].title);
    return (
        <section className={`${template.section} ${template.section_light}`}>
            <div className={`${template.container} ${template.container_small} ${style.container}`}>
                <h2 className={`${template.title} ${style.title}`}>
                    {title}
                </h2>
            </div>
            <div className={`${template.container} ${template.container_large}`}>
                <TechnologiesButtons activeTitle={technologyListActive} setActive={setTechnologyListActive} technologies={technologies}/>
                <div className={`${style.technologies__table}`}>
                    {
                        technologies.map((item: ITechnologies, index: number) => {
                            return(
                                <TechnologiesTable key={index} title={item.title} technologies={item.items} isActive={technologyListActive} />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default TechnologiesSection;
